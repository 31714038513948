<template>
  <spinner v-if="verify_url"/>
  <auth-layout v-else :background-image="imgUrl">
    <b-card-title title-tag="h2" class="font-weight-bold mb-1">
      {{ $t("auth.verify-email.welcome") }}
    </b-card-title>
    <b-card-text class="mb-2 justify">
      {{ $t("auth.verify-email.call-to-action") }}
    </b-card-text>

    <validation-observer ref="otpValidation">
      <b-form ref="otp-form">
        <validation-provider
            #default="{ errors }"
            vid="verification_code"
            :name="$t('fields.verification_code')"
            rules="required"
        >
          <div class="d-flex justify-content-center mb-1 flex-column">
            <otp-input
                v-model="otp"
                class="align-self-center"
                input-classes="otp-input"
                :num-inputs="6"
                input-type="number"
                :separator="''"
            />
            <small class="align-self-center font-small-3 mt-50 text-danger">
              {{ errors[0] }}
            </small>
          </div>
        </validation-provider>
        <div class="d-flex mb-3 justify-content-center">
          <div class="d-flex">
            <b-button
                variant="primary"
                block
                type="submit"
                @click.prevent="verify"
                :disabled="loading || verifying || otp.length !== 6"
            >
              <b-spinner small v-if="verifying"/>
              <span class="sr-only" v-if="verifying"> {{ $t("auth.verify-email.verifying") }}</span>
              <span v-if="!verifying">{{ $t("auth.verify-email.verify") }}</span>
            </b-button>
          </div>
        </div>
      </b-form>
    </validation-observer>

    <b-row>
      <b-col class="p-50" style="min-width: 195px;">
        <b-button
            variant="primary"
            block
            type="submit"
            @click.prevent="validationForm"
            :disabled="loading || verifying"
        >
          <b-spinner small v-if="loading"/>
          <span class="sr-only" v-if="loading"> {{ $t("general.loading") }}</span>
          <span v-if="!loading">{{ $t("auth.verify-email.action") }}</span>
        </b-button>
      </b-col>
      <b-col class="p-50" style="min-width: 195px;">
        <b-button
            variant="primary"
            block
            type="submit"
            @click.prevent="logout()"
            :disabled="loading || verifying"
        >
          <span>{{ $t("auth.verify-email.sign-in-another-account") }}</span>
        </b-button>
      </b-col>
    </b-row>
  </auth-layout>
</template>

<script>
import AuthLayout from "./components/AuthLayout.vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import {required, email} from "@validations";
import {togglePasswordVisibility} from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import OtpInput from "@/components/OtpInput";
import {BRow, BCol} from 'bootstrap-vue'

import store from "@/store/index";

import {resendEmailVerification, verifyEmail} from "@/api/auth.api";
import Spinner from "@/components/Spinner.vue";

export default {
  name: 'VerifyEmail',
  components: {AuthLayout, ValidationProvider, ValidationObserver, Spinner, OtpInput, BRow, BCol},
  mixins: [togglePasswordVisibility],
  beforeRouteLeave(to, from, next) {
    if (this.$auth.user()?.email_verified_at) {
      next();
    } else if (!this.$auth.check() && to.name === 'login') {
      next();
    } else {
      next(false);
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/forgot-password-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  data() {
    return {
      loading: false,
      verifying: false,
      verify_url: null,
      sideImg: require("@/assets/images/pages/forgot-password-v2.svg"),
      form: {
        password: "",
      },
      otp: '',
      required,
      email,
    };
  },
  methods: {
    async validationForm() {
      try {
        this.loading = true;
        const result = await resendEmailVerification();
        if (result.error) {
          this.$refs.form.setErrors(result.error);
        } else if (result.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("auth.verify-email.resend-success"),
              icon: "CheckIcon",
              variant: "success",
            },
          });
        }
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
    async verify() {
      try {
        this.verifying = true;
        const result = await verifyEmail(this.otp);
        if (result.error) {
          this.$refs.otpValidation.setErrors(result.error);
        } else if (result.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("auth.verify-email.success"),
              icon: "CheckIcon",
              variant: "success",
            },
          });
          await this.$auth.fetch({});
          await this.$router.push({name: "admin-dashboard"});
        }
      } catch (e) {
      } finally {
        this.verifying = false;
      }
    },
    logout() {
      this.$auth.logout();
    },
  },
  mounted() {
    this.verify_url = this.$route.query.verify_url;

    if (this.$auth.user()?.email_verified_at) {
      this.$router.push({name: "admin-dashboard"});
    }

    if (this.verify_url) {
      this.axios.get(this.verify_url).then(async () => {
        await this.$auth.fetch();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("auth.verify-email.success"),
            icon: "CheckIcon",
            variant: "success",
          },
        });
        this.$router.push({name: "admin-dashboard"});
      });
    }
  },
};
</script>

<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 5px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}

.otp-input.error {
  border: 1px solid red !important;
}

.otp-input::-webkit-inner-spin-button, .otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.text-danger {
  color: #ff675c !important;
}
</style>